import {
  BlockFormSchema as AccordionBlockFormSchema,
  BlockSchema as AccordionBlockSchema,
  CollapsibleGroupProps
} from 'components/blocks/CollapsibleGroup';
import { BlockOption, BlockProps, PartialBlockProps } from './types';
import {
  BlockFormSchema as CarouselBlockFormSchema,
  BlockSchema as CarouselBlockSchema,
  CarouselProps
} from 'components/blocks/carousels/Carousel';
import {
  BlockFormSchema as CounterBlockFormSchema,
  BlockSchema as CounterBlockSchema,
  CounterProps
} from 'components/blocks/Counter';
import {
  BlockFormSchema as FormBuilderBlockFormSchema,
  BlockSchema as FormBuilderBlockSchema,
  FormBuilderProps
} from 'components/blocks/FormBuilder';
import {
  BlockFormSchema as HeroBlockFormSchema,
  BlockSchema as HeroBlockSchema,
  HeroProps
} from 'components/blocks/Hero';
import {
  BlockFormSchema as HorizontalLineBlockFormSchema,
  BlockSchema as HorizontalLineBlockSchema,
  HorizontalLineProps
} from 'components/blocks/HorizontalLine';
import {
  BlockFormSchema as IconListBlockFormSchema,
  BlockSchema as IconListBlockSchema,
  IconListProps
} from 'components/blocks/IconList';
import {
  BlockFormSchema as ImageSectionBlockFormSchema,
  BlockSchema as ImageSectionBlockSchema,
  ImageSectionProps
} from 'components/blocks/ImageSection';
import {
  BlockFormSchema as MediaGroupBlockFormSchema,
  BlockSchema as MediaGroupBlockSchema,
  MediaGroupProps
} from 'components/blocks/MediaGroup';
import {
  BlockFormSchema as ProfileBlockFormSchema,
  BlockSchema as ProfileBlockSchema,
  ProfileSectionProps
} from 'components/blocks/ProfileSection';
import {
  BlockSchema as SideBySideBlockSchema,
  BlockFormSchema as SideBySideFormSchema,
  SideBySideProps
} from 'components/blocks/SideBySide';
import {
  BlockFormSchema as SocialLinksBlockFormSchema,
  BlockSchema as SocialLinksBlockSchema,
  SocialLinksProps
} from '../blocks/SocialLinks';
import {
  BlockFormSchema as TestimonialBlockFormSchema,
  BlockSchema as TestimonialBlockSchema
} from 'components/blocks/TestimonialSection';
import { TestimonialProps } from 'components/blocks/Testimonial';
import {
  BlockFormSchema as ThreeBoxComponentBlockFormSchema,
  BlockSchema as ThreeBoxComponentBlockSchema,
  ThreeBoxComponentProps
} from 'components/blocks/ThreeBoxComponent';
import {
  BlockFormSchema as TypographyBlockFormSchema,
  BlockSchema as TypographyBlockSchema,
  TypographyProps
} from 'components/blocks/Typography';
import {
  BlockFormSchema as VideoBlockFormSchema,
  BlockSchema as VideoBlockSchema,
  VideoProps
} from 'components/blocks/Video';
import {
  BlockFormSchema as VideoBoxBlockFormSchema,
  BlockSchema as VideoBoxBlockSchema,
  VideoBoxSectionProps
} from 'components/blocks/VideoBoxSection';
import {
  BlockFormSchema as VideoCarouselBlockFormSchema,
  BlockSchema as VideoCarouselBlockSchema,
  VideoCarouselProps
} from 'components/blocks/carousels/VideoCarousel';

import {
  BlockFormSchema as GiveBlockFormSchema,
  GiveBlockProps,
  BlockSchema as GiveBlockSchema
} from 'components/blocks/Give';

import {
  BlockFormSchema as CampaignHeaderBlockFormSchema,
  BlockSchema as CampaignHeaderBlockSchema,
  BlockProps as CampaignHeaderProps
} from 'components/blocks/CampaignHeader';

import {
  BlockFormSchema as ImageBlockFormSchema,
  BlockSchema as ImageBlockSchema,
  BlockProps as ImageProps
} from 'components/blocks/Image';

import {
  BlockFormSchema as LogoHeaderBlockFormSchema,
  BlockSchema as LogoHeaderBlockSchema,
  BlockProps as LogoHeaderProps
} from 'components/blocks/LogoHeader';

import {
  BlockFormSchema as StatesMapBlockFormSchema,
  BlockSchema as StatesMapBlockSchema
} from 'components/blocks/StatesMap';
import { StatesMapProps } from 'components/blocks/StatesMap/types';
import { idBuilder } from 'utils/id';
import React from 'react';
import dynamic from 'next/dynamic';

export const AvailableBlocksList: BlockOption[] = [
  {
    name: 'Accordion',
    id: 'CollapsibleGroup',
    description: 'Accordion used often for FAQs',
    image: '/editor-icons/Acordian.png',
    formSchema: AccordionBlockFormSchema,
    validationSchema: AccordionBlockSchema
  },
  {
    name: 'Carousel',
    id: 'Carousel',
    description: 'Carousel with side by side items',
    image: '/editor-icons/Carousel.png',
    formSchema: CarouselBlockFormSchema,
    validationSchema: CarouselBlockSchema
  },
  {
    name: 'Counter',
    id: 'Counter',
    description: 'Section with a 3 numeric counts and text',
    image: '/editor-icons/Counter.png',
    formSchema: CounterBlockFormSchema,
    validationSchema: CounterBlockSchema
  },
  {
    name: 'Media',
    id: 'MediaGroup',
    description: 'Used for linking to external media post',
    image: '/editor-icons/Media.png',
    formSchema: MediaGroupBlockFormSchema,
    validationSchema: MediaGroupBlockSchema
  },
  {
    name: 'Profile Section',
    id: 'ProfileSection',
    description: 'Profile circles with image and information',
    image: '/editor-icons/ProfileSection.png',
    formSchema: ProfileBlockFormSchema,
    validationSchema: ProfileBlockSchema
  },
  {
    name: 'Hero',
    id: 'Hero',
    description: 'Hero section with background image and text and cta',
    image: '/editor-icons/Hero.png',
    formSchema: HeroBlockFormSchema,
    validationSchema: HeroBlockSchema
  },
  {
    name: 'Horizontal Line Section',
    id: 'HorizontalLine',
    description:
      'Section with a horizontal line that can be used as a section divider',
    formSchema: HorizontalLineBlockFormSchema,
    validationSchema: HorizontalLineBlockSchema
  },
  {
    name: 'Icon List',
    id: 'IconList',
    description: 'Section with a list with large numbers, an icon and content',
    image: '/editor-icons/IconList.png',
    formSchema: IconListBlockFormSchema,
    validationSchema: IconListBlockSchema
  },
  {
    name: 'Image',
    id: 'Image',
    description: 'Full width responsive image',
    image: '/editor-icons/Image.png',
    formSchema: ImageBlockFormSchema,
    validationSchema: ImageBlockSchema
  },
  {
    name: 'Image Section',
    id: 'ImageSection',
    description: 'Image Boxes with an optional label',
    image: '/editor-icons/ImageSection.png',
    formSchema: ImageSectionBlockFormSchema,
    validationSchema: ImageSectionBlockSchema
  },
  {
    name: 'Side By Side',
    id: 'SideBySide',
    description: 'Section with two columns with text or image/video',
    image: '/editor-icons/Side_by_Side.png',
    formSchema: SideBySideFormSchema,
    validationSchema: SideBySideBlockSchema
  },
  {
    name: 'Social Links',
    id: 'SocialLinks',
    description: 'Social Link Boxes',
    image: '/editor-icons/Socials.png',
    formSchema: SocialLinksBlockFormSchema,
    validationSchema: SocialLinksBlockSchema
  },
  {
    name: 'Submission Form',
    id: 'FormBuilder',
    description: 'Section with a submission form',
    image: '/editor-icons/Form.png',
    formSchema: FormBuilderBlockFormSchema,
    validationSchema: FormBuilderBlockSchema
  },
  {
    name: 'Testimonial Section',
    id: 'TestimonialSection',
    description: 'Testimonials',
    image: '/editor-icons/Testimonials.png',
    formSchema: TestimonialBlockFormSchema,
    validationSchema: TestimonialBlockSchema
  },
  {
    name: 'Text Block',
    id: 'Typography',
    description: 'Section with text or text with ctas',
    image: '/editor-icons/TextBlock.png',
    formSchema: TypographyBlockFormSchema,
    validationSchema: TypographyBlockSchema
  },
  {
    name: 'Three Box Section',
    id: 'ThreeBoxComponent',
    description: 'Section with a 3 content boxes, title and subtitle',
    image: '/editor-icons/ThreeBox.png',
    formSchema: ThreeBoxComponentBlockFormSchema,
    validationSchema: ThreeBoxComponentBlockSchema
  },
  {
    name: 'Video',
    id: 'Video',
    description: 'Full width Vimeo Video Embed',
    image: '/editor-icons/Video.png',
    formSchema: VideoBlockFormSchema,
    validationSchema: VideoBlockSchema
  },
  {
    name: 'Video Box Section',
    id: 'VideoBoxSection',
    description: 'Vimeo Video Boxes with lightbox player',
    image: '/editor-icons/VideoBoxSection.png',
    formSchema: VideoBoxBlockFormSchema,
    validationSchema: VideoBoxBlockSchema
  },
  {
    name: 'Video Carousel',
    id: 'VideoCarousel',
    description: 'Full width video in a carousel',
    image: '/editor-icons/VideoCarousel.png',
    formSchema: VideoCarouselBlockFormSchema,
    validationSchema: VideoCarouselBlockSchema
  },
  {
    name: 'Give Module',
    id: 'Give',
    description: 'Give Module',
    hideFromList: true,
    formSchema: GiveBlockFormSchema,
    validationSchema: GiveBlockSchema
  },
  {
    name: 'Campaign Header',
    id: 'CampaignHeader',
    description: 'Give Campaign Header',
    hideFromList: true,
    formSchema: CampaignHeaderBlockFormSchema,
    validationSchema: CampaignHeaderBlockSchema
  },
  {
    name: 'Logo Header',
    id: 'LogoHeader',
    description: 'Homepage Logo Header',
    hideFromList: false,
    formSchema: LogoHeaderBlockFormSchema,
    validationSchema: LogoHeaderBlockSchema
  },
  {
    name: 'States Map',
    id: 'StatesMap',
    description: 'States Map with active states and links',
    hideFromList: false,
    formSchema: StatesMapBlockFormSchema,
    validationSchema: StatesMapBlockSchema
  }
];
type AvailableBlockProps =
  | CollapsibleGroupProps
  | CounterProps
  | HeroProps
  | HorizontalLineProps
  | MediaGroupProps
  | SideBySideProps
  | ProfileSectionProps
  | VideoProps
  | VideoBoxSectionProps
  | TestimonialProps
  | TypographyProps
  | FormBuilderProps
  | VideoCarouselProps
  | CarouselProps
  | ThreeBoxComponentProps
  | SocialLinksProps
  | IconListProps
  | ImageSectionProps
  | GiveBlockProps
  | CampaignHeaderProps
  | ImageProps
  | LogoHeaderProps
  | StatesMapProps;

export const BlockComponentMap: {
  [key: string]: React.ComponentType<AvailableBlockProps>;
} = {
  // @ts-ignore
  CollapsibleGroup: dynamic(() => import('../blocks/CollapsibleGroup')),
  // @ts-ignore
  Counter: dynamic(() => import('../blocks/Counter')),
  // @ts-ignore
  Carousel: dynamic(() => import('../blocks/carousels/Carousel')),
  // @ts-ignore
  CampaignHeader: dynamic(() => import('../blocks/CampaignHeader')),
  // @ts-ignore
  Give: dynamic(() => import('../blocks/Give')),
  // @ts-ignore
  EventsList: dynamic(() => import('../blocks/FixedBlock')),
  // @ts-ignore
  Hero: dynamic(() => import('../blocks/Hero')),
  // @ts-ignore
  HorizontalLine: dynamic(() => import('../blocks/HorizontalLine')),
  // @ts-ignore
  Image: dynamic(() => import('../blocks/Image')),
  // @ts-ignore
  MediaGroup: dynamic(() => import('../blocks/MediaGroup')),
  // @ts-ignore
  SideBySide: dynamic(() => import('../blocks/SideBySide')),
  // @ts-ignore
  ProfileSection: dynamic(() => import('../blocks/ProfileSection')),
  // @ts-ignore
  ThreeBoxComponent: dynamic(() => import('../blocks/ThreeBoxComponent')),
  // @ts-ignore
  Video: dynamic(() => import('../blocks/Video')),
  // @ts-ignore
  VideoBoxSection: dynamic(() => import('../blocks/VideoBoxSection')),
  // @ts-ignore
  TestimonialSection: dynamic(() => import('../blocks/TestimonialSection')),
  // @ts-ignore
  Typography: dynamic(() => import('../blocks/Typography')),
  // @ts-ignore
  FormBuilder: dynamic(() => import('../blocks/FormBuilder')),
  //@ts-ignore
  VideoCarousel: dynamic(() => import('../blocks/carousels/VideoCarousel')),
  //@ts-ignore
  SocialLinks: dynamic(() => import('../blocks/SocialLinks')),
  // @ts-ignore
  IconList: dynamic(() => import('../blocks/IconList')),
  // @ts-ignore
  ImageSection: dynamic(() => import('../blocks/ImageSection')),
  // @ts-ignore
  LogoHeader: dynamic(() => import('../blocks/LogoHeader')),
  // @ts-ignore
  StatesMap: dynamic(() => import('../blocks/StatesMap'))
};

export const MapPartialBlock = (block: PartialBlockProps): BlockProps => {
  const blockOption = AvailableBlocksList.find(b => b.id === block.id);
  return {
    ...block,
    ...blockOption!,
    key: block.key ?? idBuilder()
  };
};
